import React from 'react'
import {graphql} from "gatsby";
import SEO from "../components/seo";
import {Container} from "@material-ui/core";
import {MDXProvider} from "@mdx-js/react";
import components from "../components/mdx_components";
import {MDXRenderer} from "gatsby-plugin-mdx";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";

const Page = ({data}) => (
  <React.Fragment>
    <SEO title="Impressum"/>
    <Container maxWidth="md">
      <Box my={1}>
        <Card>
          <Box m={1}>
            <MDXProvider components={components}><MDXRenderer>{data.mdx.body}</MDXRenderer></MDXProvider>
          </Box>
        </Card>
      </Box>
    </Container>
  </React.Fragment>
)

export default Page

export const pageQuery = graphql`
    query {
        mdx(frontmatter: { title: { eq: "Impressum" } }) {
            body
        }
    }
`
